<template>
  <div
    class="container mx-auto py-6 flex items-center justify-between"
  >
    <div class="flex items-center gap-2">
      <NuxtLink class="block" to="/">
        <NuxtImg src="img/logoclear.png" height="30" />
      </NuxtLink>

      <div>
        <div class="opacity-50">Mode invité</div>
      </div>
    </div>

    <NuxtLink to="/login">
      <Button
        text
        icon="pi pi-sign-in"
        label="Se connecter"
        class="p-button-sm"
      />
    </NuxtLink>
  </div>
</template>
